import iconsHref from "./index.svg";

export type SpriteName =
  | "logo"
  | "logo-full"
  | "instagram"
  | "github"
  | "facebook"
  | "x"
  | "menu"
  | "client-prevailhq-logo-light"
  | "client-prevailhq-logo-dark"
  | "client-powerley-logo-light"
  | "client-powerley-logo-dark"
  | "client-mcgrawhill-logo-light"
  | "client-mcgrawhill-logo-dark"
  | "client-irl-logo-light"
  | "client-irl-logo-dark"
  | "client-grindr-logo-light"
  | "client-grindr-logo-dark"
  | "client-gabb-logo-light"
  | "client-gabb-logo-dark"
  | "client-dte-energy-logo-light"
  | "client-dte-energy-logo-dark"
  | "client-drip-logo-light"
  | "client-drip-logo-dark"
  | "client-code-logo-light"
  | "client-code-logo-dark"
  | "client-chickfila-logo-light"
  | "client-chickfila-logo-dark"
  | "client-botstacks-logo-light"
  | "client-botstacks-logo-dark"
  | "client-ansa-logo-light"
  | "client-ansa-logo-dark";

export let sizes = {
  logo: { width: 320, height: 272 },
  "logo-full": { width: 1179, height: 272 },
  instagram: { width: 24, height: 24 },
  github: { width: 24, height: 24 },
  facebook: { width: 24, height: 24 },
  x: { width: 24, height: 24 },
  menu: { width: 24, height: 24 },
  "client-prevailhq-logo-light": { width: 138, height: 30 },
  "client-prevailhq-logo-dark": { width: 138, height: 30 },
  "client-powerley-logo-light": { width: 135, height: 30 },
  "client-powerley-logo-dark": { width: 135, height: 30 },
  "client-mcgrawhill-logo-light": { width: 124, height: 30 },
  "client-mcgrawhill-logo-dark": { width: 124, height: 30 },
  "client-irl-logo-light": { width: 65, height: 30 },
  "client-irl-logo-dark": { width: 65, height: 30 },
  "client-grindr-logo-light": { width: 122, height: 30 },
  "client-grindr-logo-dark": { width: 122, height: 30 },
  "client-gabb-logo-light": { width: 72, height: 30 },
  "client-gabb-logo-dark": { width: 154, height: 30 },
  "client-dte-energy-logo-light": { width: 89, height: 30 },
  "client-dte-energy-logo-dark": { width: 89, height: 30 },
  "client-drip-logo-light": { width: 111, height: 20 },
  "client-drip-logo-dark": { width: 111, height: 20 },
  "client-code-logo-light": { width: 106, height: 30 },
  "client-code-logo-dark": { width: 106, height: 30 },
  "client-chickfila-logo-light": { width: 67, height: 30 },
  "client-chickfila-logo-dark": { width: 67, height: 30 },
  "client-botstacks-logo-light": { width: 169, height: 30 },
  "client-botstacks-logo-dark": { width: 169, height: 30 },
  "client-ansa-logo-light": { width: 103, height: 30 },
  "client-ansa-logo-dark": { width: 103, height: 30 },
} as const;

export type SpriteProps = { name: SpriteName } & JSX.IntrinsicElements["svg"];

export function Svg({ name, ...svgProps }: SpriteProps) {
  return (
    <svg {...svgProps}>
      <use href={iconsHref + "#" + name} />
    </svg>
  );
}
